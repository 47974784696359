import React from "react"
import { Box, Chip, DataGrid } from "@coolblue-development/becky"
import { Cross, Plus } from '@coolblue-development/icons';
import Edit from '@mui/icons-material/Edit';
import { useSelector, useDispatch } from 'react-redux'
import { LicenseInfo } from '@mui/x-license-pro';
import {red, green, blue} from '../../../SharedComponents/Colors'
import { useNavigate } from "react-router-dom";
import {
  selectFormdata,
    updateActiveDimension,
    removeDimension,
} from '../../../Form/FormSlice'

LicenseInfo.setLicenseKey('bed5e9cf72f0e0c37528f6e9514ca254Tz0xMDI1MjAsRT0xNzY0MTUxMDA1MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y');

function getChipProps(params: GridRenderCellParams): ChipProps {

  if (params.value === "Delete") {
    return {
      icon: <Cross  style={{ fill: red }}/>,
      label: params.value,
     style: {
        borderColor: red
      }
    };
  }
  else if (params.value === "Copy"){
    return {
      icon: <Plus style={{ fill: green }}/>,
      label: params.value,
     style: {
        borderColor: green
      }
    };
  }
    else if (params.value === "Edit"){
    return {
      icon: <Edit style={{ fill: blue }}/>,
      label: params.value,
     style: {
        borderColor: blue
      }
    };
  }
}


export default function DimensionList() {
    const formData = useSelector(selectFormdata);
    console.log(formData)
    const dimensionArray = formData.dimensions
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const columns = [
      {
        field: 'id',
        headerName: 'ID',
        width: 10,
        editable: false,
      },
      {
        field: 'dimensionDefinition',
        headerName: 'Dimension Definition',
        width: 250,
        editable: false,
      },
      {
        field: 'dimensionAlias',
        headerName: 'Dimension Alias',
        width: 250,
        editable: false,
      },
      {
        field: 'Edit',
        headerName: 'Edit',
        width: 150,
        renderCell: (params) => {
            return <Chip variant="outlined" size="small" {...getChipProps(params)} />;
        },
        editable: false,
      },
      {
        field: 'Delete',
        headerName: 'Delete',
        renderCell: (params) => {
            return <Chip variant="outlined" size="small" {...getChipProps(params)} />;
        },
        width: 150,
        editable: false,
      }
    ];

    const detailsrows = dimensionArray.map((row, index) => {
        return {
            id: index + 1,
            dimensionDefinition: row.dimensionDefinition,
            dimensionAlias: row.dimensionAlias,
            Edit: 'Edit',
            Delete: 'Delete',
        }
    })


    function handleOnCellClick(params) {
        const dimension = dimensionArray.find( dimension => dimension.dimensionAlias === params.row.dimensionAlias);
        if (params.field === 'Delete') {
            console.log("Delete dimension " + params.row.dimensionAlias)
            dispatch(removeDimension(params.row.dimensionAlias))
        } else if (params.field === 'Edit') {
            dispatch(updateActiveDimension(dimension))
            navigate("/CustomAnalysis_GA4/CustomDimension");
        };
    }

    return (
    <Box>
      <DataGrid
        autoHeight
        rows={detailsrows}
        columns={columns}
        onCellClick={handleOnCellClick}
        initialState = {
			{
				columns: {
					columnVisibilityModel: {
						id: false,
					},
				},
			}
		}
      />
    </Box>
    );
}
